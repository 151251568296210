<template>
  <div class="modaloverlay" v-if="showModal">
    <div class="modal-header" style="border: none">
      <button
        type="button"
        @click="closeModal"
        class="text-white modal-close btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          fill="currentColor"
          class="bi bi-x-circle"
          viewBox="0 0 16 16"
        >
          <path
            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
          ></path>
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
          ></path>
        </svg>
      </button>
    </div>
    <div
      class="modal text-center"
      style="
        background-color: #1c232f !important;
        color: white !important;
        border: 3px solid goldenrod;
        border-radius: 25px;
      "
    >
      <div class="modal-body" style="text-align: center">
        <h1>WELCOME to "The Club of SULTAN" CB88 VIP</h1>
        <br />
        <h4>Anda Tertarik ?</h4>
        <h4>
          Website CB88 VIP dibuat Khusus Member terpilih, Member yang telah
          melalui uji Lab
        </h4>
        <h4>
          Keuangan yang terSeleksi, Member yang memenuhi Syarat Syarat Prioritas
          ( VIP ).
        </h4>
        <br />
        <h4>
          Anda Siap Bergabung ? Tapi kami belum tentu Siap Menerima Anda, Sekali
          lagi HANYA
        </h4>
        <h4>VIP KALANGAN TERTENTU yang Memenuhi SYARAT</h4>
        <br />
        <h4>Hubungi kami JIKA Anda merasa Layak menjadi "The Next SULTAN"</h4>
        <h4>Rasakan NEW EXPERIENCE of Priority Online Gaming Club.</h4>
      </div>
    </div>
  </div>
  <main class="container pt-3">
    <div class="col-lg-12 d-flex justify-content-center">
      <NuxtImg
        loading="lazy"
        src="https://cdn.it-cg.group/cbvip/Teks%20Exclusive%20For%20Sultan.webp"
        width="400"
        height="90"
        alt=""
        class="img-fluid"
      />
    </div>
    <div class="col-lg-12 pt-3 d-flex justify-content-center">
      <swiper
        :spaceBetween="30"
        :centeredSlides="true"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :navigation="false"
        :modules="modules"
        class="mySwiper slider-container"
      >
        <swiper-slide>
          <NuxtImg
            loading="lazy"
            src="/assets/images/Slider_Desktop.jpg"
            class="img-fluid"
          />
        </swiper-slide>
        <swiper-slide>
          <NuxtImg
            loading="lazy"
            src="https://cdn.it-cg.group/cbvip/Slider1.jpg"
            class="img-fluid"
          />
        </swiper-slide>
        <swiper-slide>
          <NuxtImg
            loading="lazy"
            src="https://cdn.it-cg.group/cbvip/Slider2.jpg"
            class="img-fluid"
          />
        </swiper-slide>
        <swiper-slide>
          <NuxtImg
            loading="lazy"
            src="https://cdn.it-cg.group/cbvip/Slider3.jpg"
            class="img-fluid"
          />
        </swiper-slide>
      </swiper>
    </div>
    <div class="col-lg-12 d-flex justify-content-center py-3">
      <a class="shadow" href="menu"
        ><NuxtImg
          loading="lazy"
          src="https://cdn.it-cg.group/cbvip/Tombol%20Know%20More.webp"
          width="300"
          height="94"
          alt=""
          class="img-fluid"
      /></a>
    </div>
  </main>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
  },
  mounted() {
    this.showModal = true;
  },
};
</script>

<style>
.btn-close {
  --bs-btn-close-color: #fff;
  --bs-btn-close-bg: url(
    data:image/svg + xml,
    %3csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 16 16"fill="%23000"%3e%3cpathd="M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z"/%3e%3c/svg%3e
  );
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.35em 0.35em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y))
    calc(-0.5 * var(--bs-modal-header-padding-x))
    calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal {
  display: block;
  position: fixed;
  z-index: 99999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  margin: auto;
  display: block;
  width: 100% !important;
  max-width: 700px;
}

h4 {
  font-size: 15px !important;
}

h1 {
  font-size: 37.5px !important;
}

.btn-close {
  position: absolute;
  top: 23.5vh;
  right: 48.5vh;
  z-index: 9999999999999 !important;
  color: white !important;
  font-weight: 800;
}

@media screen and (max-width: 700px) {
  .modal {
    width: 75% !important;
    height: 50% !important;
    margin: 0 auto; /* Center the modal horizontally */
    position: absolute;
    top: 50%; /* Center the modal vertically */
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .btn-close {
    position: absolute;
    top: 23.5vh;
    right: 70px;
    z-index: 9999999999999 !important;
    color: white !important;
    font-weight: 800;
  }

  h4 {
    font-size: 13px !important;
  }

  h1 {
    font-size: 25px !important;
  }

  h1 {
    margin-top: -10vh !important;
  }
}
</style>
